import Vue from 'vue'
import axios from 'axios'
import { debounce } from 'lodash'
import { ClickOutside } from './utils/click-outside'
import { API } from './constants'
import { captureException } from './utils/sentry'
Vue.config.errorHandler = (err, vm, info) => {
  captureException(err)
}
// navigation
if (document.getElementById('nav')) {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#nav',
    delimiters: ['@{', '}'],
    directives: {
      ClickOutside
    },
    data () {
      return {
        show: true,
        currencyDrop: false,
        countryChangeLoading: false
      }
    },
    computed: {
      currencyAnimation() {
        return window.innerWidth < this.screenBreak() ? 'slide' : 'scale-top-right'
      }
    },
    created () {
      if (window.innerWidth < this.screenBreak()) this.show = false
      window.addEventListener('resize', debounce(this.screenHandler, 50))
    },
    destroyed () {
      window.removeEventListener('resize', debounce(this.screenHandler, 50))
    },
    methods: {
      screenHandler (e) {
        const { innerWidth } = e.target
        if(!this.show) { this.show = innerWidth >= this.screenBreak() }
      },
      toggleNavigation () {
        this.show = !this.show
      },
      hide () {
        if (window.innerWidth <= this.screenBreak()) this.show = false
      },
      screenBreak () {
        return typeof window.user === 'object' ? 1280 : 1280
      },
      changeCountry(country = 'US') {
        if (this.countryChangeLoading === true) return false
        this.countryChangeLoading = true
        axios.post(API.USERS + '/country', { country, token: window.csrf })
          .then(() => {
            location.reload()
          })
      }
    }
  })
}
